import "core-js/modules/es.array.push.js";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import moment from "moment";
// Import the plugin here
// @ts-ignore
import { Auth0Plugin } from "./auth";
import VueApexCharts from "vue-apexcharts";
import { createPinia, PiniaVuePlugin } from "pinia";
const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
Vue.config.productionTip = false;
const base = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});
Vue.prototype.$http = base;
moment.locale("ja");
Vue.prototype.$moment = moment;
Vue.use(VueApexCharts);
Vue.component("Apexchart", VueApexCharts);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
new Vue({
  router,
  store,
  pinia,
  render: h => h(App)
}).$mount("#app");